<template>
  <div class="container-fluid py-4 add-student instr-vid">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                إضافة محاضرة أونلاين - الدورة: {{ course.name }}
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createVideo" role="form" class="text-end mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    اضافة الفيديو لدورة اخري
                    <span class="green"> (اختياري) </span>
                    <span color="danger" class="error">{{
                      errors.branch_id ? errors.branch_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="branch"
                    v-model="selectedCourse"
                    :options="courses"
                    :multiple="true"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="  اختر  الدورة"
                    @select="getVideoCourses"
                    label="name"
                    :custom-label="customLabel"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions> <span> فارغة </span> </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label> عنوان الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                    placeholder="  عنوان الفيديو "
                    v-model="video.name"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    عنوان الفيديو بالإنجليزية<span class="green">
                      (اختياري)
                    </span></label
                  >
                  <vmd-input
                    id="date-input"
                    type="text"
                    placeholder=" عنوان الفيديو بالإنجليزية "
                    v-model="video.name_en"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <div class="row">
                    <div class="mb-3 mt-4 col-lg-3">
                      <vmd-checkbox
                        id="free-video"
                        type="text"
                        v-model="video.free"
                        :value="true"
                      >
                        فديو مجاني
                      </vmd-checkbox>
                    </div>
                    <div class="mb-3 col-lg-9" v-if="!video.free">
                      <label> قيمة الفيديو<span class="red">*</span></label>
                      <vmd-input
                        id="date-input"
                        type="text"
                        placeholder=" قيمة الفيديو  "
                        v-model="video.cost"
                        :required="!video.free"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="mb-3 form-check mt-4 col-lg-6 video-type">
                <input
                  id="free-video"
                  type="radio"
                  class="form-check-input pointer"
                  v-model="videoType"
                  value="media"
                >
                 تحميل الفيديو
                <input
                  id="youtube-video"
                   class="form-check-input pointer"
                  type="radio"
                  v-model="videoType"
                  value="youtube"
                >
                رابط يوتيوب
              </div>
                              <div class="mb-3 col-lg-6" v-if="videoType === 'youtube'">
                  <label> رابط الفيديو</label>
                  <vmd-input
                    id="date-input"
                    type="text"
                    placeholder=" رابط الفيديو "
                    v-model="video.url"
                  />
                </div>
              </div>
              <div class="row align-center"  v-if="videoType === 'media'">
                <div class="col-lg-6 mb-3">
                  <!-- tabel -->
                  <table class="video-tabel1 table-bordered table-striped">
                    <tbody>
                      <tr>
                        <th scope="col">وقت التحميل</th>
                        <td>{{ currentDateTime }}</td>
                      </tr>
                      <tr>
                        <th scope="col">حجم الفيديو</th>
                        <td>{{ videoSize }} MB</td>
                      </tr>
                      <tr>
                        <th scope="col">نسبة التحميل</th>
                        <td>{{ videoProgress }}</td>
                      </tr>
                      <tr>
                        <th scope="col">تم تحميل</th>
                        <td>{{ sentSize }} MB</td>
                      </tr>
                      <tr>
                        <th scope="col">متبقي</th>
                        <td>{{ remainSize }} MB</td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- tabel -->
                </div>
                <div class="col-lg-6 mb-3" style="position: relative">
                  <label>
                    تحميل الفيديو
                    (mp4,x-flv,x-mpegURL,MP2T,3gpp,x-msvideo,x-ms-wmv)<span
                      class="red"
                      >*</span
                    ></label
                  ><br />
                  <div>
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                      @vdropzone-sending="sendingEvent"
                      @vdropzone-file-added="getFileCount"
                      @vdropzone-success="Success"
                      @vdropzone-upload-progress="checkProgress"
                      @vdropzone-error="errorMessage"
                    />
                  </div>
                </div>
                <a
                  @click="removed"
                  type="button "
                  class="my-4 mb-2 mini bottom"
                  id="del-vid"
                  variant="gradient"
                  color="danger"
                >
                  حذف</a
                >
              </div>
              <div class="row" style="margin-top: 60px !important">
                <div class="text-center col-lg-6">
                  <vmd-button :class= "{'disabled': disabledButton}" class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import vueDropzone from 'vue2-dropzone-vue3'
import swal from 'sweetalert'
import $ from 'jquery'

export default {
  name: 'CreateVideo',
  components: {
    VmdButton,
    VmdInput,
    VmdCheckbox,
    vueDropzone
  },
  data () {
    return {
      videoType: 'media',
      videoQuality: 720,
      singleVideo: '',
      mediaId: '',
      videos: [],
      courses: [],
      selectedCourse: '',
      instructorId: '',
      subjectId: '',
      videoCourses: [],
      allfiles: 0,
      videosLength: 0,
      totalSize: 0,
      videoProgress: 0,
      sentSize: 0,
      videoSize: 0,
      remainSize: 0,
      currentDateTime: '00:00:00',
      dropzoneOptions: {
        url: `${this.$hostUrl}chunk-upload`,
        thumbnailWidth: 150,
        maxFilesize: 3000,
        addRemoveLinks: false,
        autoProcessQueue: true,
        timeout: 2000000,
        chunking: true,
        chunkSize: 20000000,
        headers: { Authorization: 'Bearer ' + localStorage.token },
        dictDefaultMessage:
          '<i class="fas fa-cloud-upload-alt"></i>تحميل الفيديو'
      },
      headers: { Authorization: 'Bearer ' + localStorage.token },
      lecture: {},
      course: {},
      video: {},
      disabledButton: false,
      errors: []
    }
  },
  created () {
    const current = this
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        this.video.cost = this.course.lecture_cost
        this.video.free = 0
        this.instructorId = current.course.instructor_object.id
        this.subjectId = current.course.subject_object.id
        current.$http
          .get(
            `${current.$hostUrl}courses?instructor_id=${this.instructorId}&subject_id=${this.subjectId}`
          )
          .then((response) => {
            current.courses = response.data.data
            const courses = response.data.data.filter((item) => {
              return item.type === 1 && item.id !== this.course.id
            })
            current.courses = courses
          })
      })
  },
  mounted () {
    // $('dz-remove').click(() => {
    //   console.log('clicked')
    // })
    document.getElementById('dropzone').click(() => {
      console.log('cllll')
    })
  },
  methods: {
    // getVideoCourses () {
    //   const videoCourses = []

    //   $.each(this.selectedCourse, (key, val) => {
    //     videoCourses.push(val.id)
    //   })
    //   this.videoCourses = videoCourses
    //   console.log(this.videoCourses, this.selectedCourse)
    // },
    errorMessage (file, message, xhr) {
      $('.dz-error-message').append(
        '<h6 style="color:#fff">Client error message</h6>'
      )
      console.log(xhr, message)
    },
    checkProgress (file, progress, bytesSent) {
      this.videoProgress = parseInt(progress) + ' % '
      let sentSize = bytesSent / 1000000
      sentSize = sentSize.toFixed(1)
      this.sentSize = sentSize
      let remainSize = this.videoSize - this.sentSize
      remainSize = remainSize.toFixed(1)
      this.remainSize = remainSize
      // add data to appended elements
      $('.dz-details h4').text(this.videoProgress)
      $('.dz-details .sent-size').text(' تم تحميل : ' + ' MB ' + this.sentSize)
      $('.dz-details .remain-size').text('  المتبقي : ' + ' MB ' + remainSize)
    },
    removed () {
      $('#dropzone').removeClass('dz-started')
      $('#dropzone .dz-preview').remove()
      $('#del-vid').addClass('hide')
      this.allfiles--
      this.videosLength--
      const formData = new FormData()
      formData.append('_method', 'DELETE')
      this.errors = this.postRequest(
        formData,
        this.$hostUrl + 'remove/media/' + this.singleVideo,
        ''
      )
    },
    sendingEvent (file, xhr, formData) {
      console.log(file, xhr)
      const currentDateTime = new Date().toLocaleString()
      this.currentDateTime = currentDateTime
      // append video progress details
      $('.dz-details').append(
        '<h4 class="ratio">' + this.videoProgress + '</h4>'
      )
      $('.dz-details').append('<span class="sent-size"></span>')
      $('.dz-details').append('<h6 class="remain-size"></h6')
      let videoSize = file.size / 1000000
      videoSize = videoSize.toFixed(1)
      this.videoSize = videoSize
    },
    getFileCount () {
      this.allfiles++
      console.log('videos count', this.allfiles)
    },
    Success (file, response) {
      swal({ title: response.message, buttons: 'ok' })
      this.videosLength++
      this.videos = response.data.media_id
      this.mediaId = response.data.media_id
      this.singleVideo = response.data.id
      console.log('video', this.videos, 'succes videos =>', this.videos.length)
      $('#del-vid').addClass('display')
      $('#del-vid').show()
      $('#del-vid').removeClass('hide')
    },
    async createVideo (e) {
      e.preventDefault()
      console.log(this.allfiles, 'all', this.videosLength)
      // if (this.allfiles === this.videosLength && this.videosLength) {
      const formData = new FormData()
      const videoCourses = []

      $.each(this.selectedCourse, (key, val) => {
        videoCourses.push(val.id)
      })
      const currentCourse = []
      currentCourse.push(this.$route.params.id)
      this.videoCourses = videoCourses
      this.videoCourses.concat(currentCourse)
      if (this.videoCourses.length) {
        formData.append(
          'course_id',
          JSON.stringify(this.videoCourses.concat(currentCourse))
        )
      } else {
        formData.append('course_id', JSON.stringify(currentCourse))
      }
      formData.append('name', this.video.name)
      if (this.video.name_en) {
        formData.append('name_en', this.video.name_en)
      }
      formData.append('free', this.video.free ? 1 : 0)
      formData.append('cost', this.video.free ? 0 : this.video.cost)
      formData.append('type', this.videoType)
      if (this.videoType === 'media') {
        // eslint-disable-next-line no-unused-vars
        const videos = JSON.stringify(this.videos)
        formData.append('media_id', this.mediaId)
      } else {
        formData.append('url', this.video.url)
      }
      formData.append('watch_limit', 5)
      await this.$http
        .post(`${this.$hostUrl}videos`, formData, '/courses')
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
            this.disabledButton = true
            this.$router.push({ path: '/courses' })
          } else {
            this.errors = response.data.data
            this.disabledButton = false
          }
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            const data = (error.response || {}).data
            if (data.error) {
              this.disabledButton = false
              swal(data.message)
            }
          }
        })
      // } else {
      //   swal('لم يتم تحميل جميع الفيديوهات')
      // }
    }
  }
}
</script>
<style>
.custom-control-label {
  float: left !important;
}
.ratio,
.remain-size {
  color: #fff;
  font-size: 17px !important;
  margin-top: 8px;
  font-weight: normal;
}
.remain-size {
  font-size: 13px !important;
  margin-top: 4px;
}

.dropzone .dz-preview .dz-progress {
  left: 29% !important;
  right: 11% !important;
}
.dropzone__box {
  width: 100% !important;
  background: #f8f9fa !important;
  min-height: 40px !important;
  cursor: pointer !important;
}
.video-header {
  justify-content: center;
  align-items: center;
  padding: 18px !important;
}
.video-header .tab-div {
  width: 75% !important;
  margin-top: 0 !important;
}
.margin-top {
  margin-top: 20px !important;
}
.mini {
  position: absolute !important;
  width: 15% !important;
  position: absolute !important;
  left: 8% !important;
  bottom: 130px !important;
  opacity: 0 !important;
  background: red;
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
}
.mini:hover {
  color: #fff !important;
}
.bottom {
  bottom: 113px !important;
}
.display {
  opacity: 1 !important;
  z-index: 9999;
}
.hide {
  opacity: 0 !important;
}
.video-tabel1 {
  position: unset !important;
  width: 100% !important;
  text-align: right !important;
  border-width: 1px !important;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 10px;
  right: 30px;
}
.video-tabel1 th {
  font-weight: normal;
  border-width: unset !important;
  width: 38%;
  background: #2b3136;
  color: #fff !important;
  padding: 7px 10px;
}
.video-tabel1 td {
  text-align: center !important;
  font-weight: bold;
}
.video-tabel1 .table-bordered > :not(caption) > * > * {
  border-width: unset !important;
}
.video-type input:nth-child(2) {
  margin-right: 30px
}
.instr-vid .form-check:not(.form-switch)
.form-check-input[type=radio]:after {
    background-color: #52ac56 !important;
}
</style>
